header {
  position: absolute;
  width: 100%;
  z-index: 5;
  top: 0px;
  background: black;
  position: fixed;
  border-bottom: 1px solid #333333;
}

header.container {
  max-width: 100%;
}
header.header {
  left: 50%;
  /*padding:5px;*/
  padding-left: 100px;
  transform: translateX(-50%);
  backdrop-filter: blur(10px);
  background-color: rgba(0, 0, 0, 0.85);
}
/* .navbar-nav {
    margin-right: auto!important;
    margin-left: auto!important;
    font-family: 'BandeinsStrangeSB';
} */

.navbar {
  padding-top: 0;
  padding-bottom: 0;
}

.navbar.bg-transparent .navbar-nav a.nav-link {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 14px;
  color: #fff;
  transition: 0.2s all;
  font-family: "TTFirsNeue-Medium";
  text-transform: uppercase;
  font-weight: normal;
  border-left: 1px solid #333333;
  letter-spacing: 0px;
}

.navbar .navbar-brand {
  position: absolute;
  left: 0px;
  color: #fff;
  font-size: 24px;
  letter-spacing: 1px;
  padding-top: 20px;
  padding-bottom: 20px;
}
.navbar .navbar-brand img {
  height: 20px;
}
.navbar-light .navbar-toggler {
  border: none;
}
.main-logo {
  margin-right: 24px;
}
.main-logo path {
  opacity: 0;
}
.navbar.navbar-light .navbar-toggler-icon {
  background: transparent;
  height: 2px;
  position: relative;
  transition: 0.25s ease-in-out;
}
.dark-header .navbar.navbar-light .collapsed .navbar-toggler-icon,
.dark-header .navbar.navbar-light .collapsed span.navbar-toggler-icon:before,
.dark-header .navbar.navbar-light .collapsed span.navbar-toggler-icon:after {
  background: #000;
}

.navbar.navbar-light .collapsed .navbar-toggler-icon {
  background: #fff;
  left: 0px;
}

.navbar.navbar-light span.navbar-toggler-icon:before {
  background: #000;
  content: "";
  position: absolute;
  top: 0px;
  height: 2px;
  width: 100%;
  left: 0px;
  transition: 0.25s ease-in-out;
  transform: rotate(45deg);
}

.navbar.navbar-light .collapsed span.navbar-toggler-icon:before {
  background: #fff;
}
.navbar.navbar-light .collapsed span.navbar-toggler-icon:after {
  background: #fff;
}
.navbar.navbar-light .collapsed span.navbar-toggler-icon:before {
  transform: rotate(0deg);
  top: -10px;
}
.navbar.navbar-light span.navbar-toggler-icon:after {
  background: #000;
  content: "";
  position: absolute;
  transform: rotate(138deg);
  top: 0px;
  height: 2px;
  width: 100%;
  left: 0px;
  transition: 0.25s ease-in-out;
}
.navbar.navbar-light .collapsed span.navbar-toggler-icon:after {
  transform: rotate(0deg);
  top: 10px;
}

.navbar-toggler:focus {
  box-shadow: none;
}

.btn.darkbtn span {
  margin-right: 15px;
}

.discord-link {
  position: absolute;
  right: 0px;
}
.black-logo {
  display: none;
}
.home-link-mobile {
  display: none;
}
.join-discord-mobile {
  margin-right: 0px;
  position: absolute;
  right: 80px;
  display: none;
}

.new-feature {
  font-size: 12px;
  line-height: 15px;
  color: #00ff95;
  border: 1px solid #00ff95;
  padding: 4px;
  margin-right: 7px;
  border-radius: 4px;
}

#connectWallet {
  font-family: "TTFirsNeue-Medium";
  margin: 10px;
  display: none;
}
.user-icon {
  margin: 10px 20px;
  border-radius: 4px;
  color: #fff;
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.user-profile {
  background-image: linear-gradient(135deg, #00ff95, #fff);
  height: 30px;
  width: 30px;
  display: inline-block;
  border-radius: 50%;
  position: relative;
  top: 10px;
  margin-right: 20px;
}
.profile-url {
  text-decoration: none;
}
@media only screen and (min-width: 769px) {
  /* .navbar.bg-transparent .navbar-nav a.nav-link:hover {
        color: #fff;
        transform: scale(1.1);
    } */
}
