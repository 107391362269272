.mm-teleport {
  height: 100vh;
  width: 100vw;
  background-image: url("/src/assets/imgs/bg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #000;
}

.btn.darkbtn {
  background: #fa8564;
  border-radius: 8px;
  color: #000;
  border: none;
  font-size: 16px;
  letter-spacing: 0px;
  line-height: 52px;
  padding: 0 20px;
  border: none !important;
}
.portal {
  width: 472px;
  box-sizing: border-box;
  padding: 40px 40px 40px 56px;
  border: 1px solid #3c3c3c;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  font-size: 14px;
  line-height: 20px;
  background: rgba(0, 0, 0, 0.75);
  color: #ffffff;
}
.portal h1 {
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 24px;
}
#connect-wallet {
  margin-top: 24px;
  font-size: 14px;
  width: 100%;
}

.modal-dialog .btn.darkbtn {
  font-family: "TTFirsNeue-Medium";
  font-size: 16px;
}
.modal-dialog .btn.darkbtn {
  width: 100%;
}
.modal-dialog .btn.darkbtn:hover {
  border: 1px solid #000;
}
.no-nft-modal .modal-bottom {
  margin-top: 32px;
}
.modalError {
  margin-top: 24px;
  background: rgba(255, 94, 67, 0.25);
  border-radius: 4px;
  padding: 16px;
  text-align: center;
  color: #db4830;
  font-size: 12px;
}
.modalSuccess {
  margin-top: 24px;
  background: #00cc77;
  border-radius: 4px;
  padding: 16px;
  text-align: center;
  color: #fff;
  font-size: 12px;
}
.no-nft-modal {
  text-align: center;
  line-height: 24px;
}
.redemption-spinner {
  text-align: center;
  margin: 40px 0;
}

.spinner-border {
  width: 60px;
  height: 60px;
}
.notMainNet {
  position: relative;
  top: 62px;
  background: #ffd7d0;
  color: #db4830;
  font-size: 14px;
  line-height: 20px;
  padding: 10px;
  z-index: 1;
  cursor: pointer;
  text-align: center;
}
