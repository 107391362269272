@import url("https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css");

@font-face {
  font-family: "TTFirsNeue-Regular";
  src: url("/src/assets/fonts/tt/TTFirsNeue-Regular.woff2") format("woff2");
}
@font-face {
  font-family: "TTFirsNeue-DemiBold";
  src: url("/src/assets/fonts/tt/TTFirsNeue-DemiBold.woff2") format("woff2");
}

@font-face {
  font-family: "TTFirsNeue-Medium";
  src: url("/src/assets/fonts/tt/TTFirsNeue-Medium.woff2") format("woff2");
}
@font-face {
  font-family: "Uncode";
  src: url("/src/assets/fonts/uncode.woff2") format("woff2");
}

html,
body {
  min-height: 100%;
  font-family: "TTFirsNeue-Regular";
}

.TTFirsNeue {
  font-family: "TTFirsNeue-Regular" !important;
}

.TTFirsNeue-Medium {
  font-family: "TTFirsNeue-Medium" !important;
}
.unCode {
  font-family: "Uncode" !important;
}
.ogdrop-wrapper {
  font-family: "TTFirsNeue-Regular";
  background: #000;
}

.demibold {
  font-family: "TTFirsNeue-DemiBold" !important;
}

.header,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "TTFirsNeue-DemiBold", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: 600;
}
body {
  margin: 0;
  font-family: "TTFirsNeue-Regular", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.btn.darkbtn.greenBtn {
  background: #00ff95;
  border-radius: 8px;
  color: #000;
  border: none;
  font-size: 16px;
  letter-spacing: 0px;
  line-height: 52px;
  padding: 0 20px;
}

a.link-orange,
.color-orange {
  color: #fa8564;
  text-decoration: none;
}
.btn {
  cursor: pointer;
}
.btn.lightbtn {
  border-radius: 8px;
  color: #fff;
  border: 2px solid #fff;
  font-size: 16px;
  letter-spacing: 0px;
  line-height: 52px;
  padding: 0 20px;
}

.default-modal {
  font-size: 14px;
}

.modal-header {
  border: none;
  padding-bottom: 24px;
  padding-top: 40px;
}
.modal-body {
  padding: 0 56px;
  padding-bottom: 40px;
}
.modal-title {
  text-align: center;
  width: 100%;
  line-height: 32px;
  padding: 0 50px;
}
.default-modal .redemption-header,
.default-modal .redemption-body {
  padding-left: 64px;
  margin-bottom: 5px;
}
.modal-dialog {
  max-width: 536px;
  line-height: 20px;
}

.modal-dialog button.btn-close {
  position: absolute;
  top: 34px;
  right: 34px;
}
