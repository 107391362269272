@media only screen and (max-width: 992px) {
  .body {
    line-height: 24px;
  }
  .container {
    padding-left: 20px;
    padding-right: 20px;
  }
  .main-text {
    font-size: 41px;
  }

  .new-feature {
    position: relative;
    top: 18px;
    float: right;
  }

  .main-subtext {
    font-size: 14px;
  }
  header.header {
    padding: 0px;
  }
  .navbar .navbar-brand {
    position: static;
    font-size: 0;
    margin-left: 10px;
  }
  .mobile-nav-logo {
    display: block;
    position: absolute;
    top: 12px;
    left: 12px;
  }
  .navbar.bg-transparent .navbar-nav a.nav-link {
    color: white;
  }
  .navbar-light .navbar-toggler {
    position: relative;
    z-index: 10;
  }
  .navbar.navbar-light span.navbar-toggler-icon:before,
  .navbar.navbar-light span.navbar-toggler-icon:after {
    background: white;
  }

  .navbar.bg-transparent .navbar-nav a.nav-link {
    text-transform: none;
  }

  .navbar-nav {
    text-align: left;
    margin-top: 50px;
    display: none;
  }

  .navbar-collapse.show {
    background: black;
    height: 100vh;
    position: fixed;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
  }
  .show .navbar-nav {
    display: block;
  }
  .portal-text-left {
    width: 100%;
  }
  .discord-link {
    position: absolute;
    bottom: 10px;
    width: 100%;
    padding: 0 20px;
  }
  .discord-link .join-discord-nav {
    width: 100%;
    line-height: 50px;
    border-radius: 3px;
    display: block;
  }

  .feature {
    margin-bottom: 50px;
  }
  .navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 1%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  }

  #intro-video {
    width: 72%;
  }
  .video-desc {
    font-size: 20px;
    line-height: 30px;
    margin-top: 30px;
    width: 100%;
    padding: 0 20px;
  }
  .page-section {
    margin: 100px auto;
  }

  .section-header {
    font-size: 18px;
    font-weight: 700;
  }

  .section-subheader {
    font-size: 14px;
  }

  .brand {
    margin-right: 0;
    width: 50%;
    display: inline-block;
    margin-bottom: 30px;
    padding: 0 35px;
  }

  .brand img {
    width: 100%;
    max-height: 50px;
  }

  .row.features-row {
    margin-top: 50px;
  }

  .feature-image {
    height: 120px;
  }
  .features-wrap {
    padding: 10px;
    padding-bottom: 0px;
  }
  h4.feature-title {
    font-size: 16px;
    font-weight: 700;
  }

  h4.feature-subtitle {
    font-size: 12px;
  }

  .product-feature:not(:last-child) {
    margin-bottom: 40px;
  }

  .product-train {
    height: 300px;
  }

  .early-access-container {
    padding: 10px;
  }

  .early-access-wrap {
    padding: 20px;
  }

  .early-access h3 {
    font-size: 18px;
  }

  .early-access h4 {
    font-size: 12px;
  }

  .early-access input {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
  }

  .faq-wrap .accordion-button {
    font-size: 14px;
  }

  .contact-link-holder a {
    font-size: 30px;
    font-weight: 600;
  }

  .contact-link-holder {
    padding: 10px;
  }

  @keyframes animatedBackgroundright {
    from {
      background-position: 0 0;
    }
    to {
      background-position: 100% 0;
    }
  }
  @keyframes animatedBackgroundleft {
    from {
      background-position: 100% 0;
    }
    to {
      background-position: 0 0;
    }
  }
  .product-train-1 {
    animation: animatedBackgroundleft 20s linear infinite;
  }
  .product-train-2 {
    animation: animatedBackgroundright 20s linear infinite;
  }
  .models-list,
  .contact-us-wrap {
    background-size: cover;
  }
  /*
  .metaverse-section {
    padding-top: 196px;
  }*/

  .og-title {
    text-align: center;
  }
  .metaverse-desc {
    font-size: 18px;
    line-height: 24px;
    width: 100%;
  }
  .metaverse-section.page-section {
    margin-bottom: 50px;
  }

  .metaverse-brands {
    margin-top: 40px;
  }
  .meta-brand img {
    height: 24px;
    width: auto;
  }
  .features-container {
    padding: 0px;
  }
  .metaverse-brands .meta-brand {
    margin-bottom: 20px;
    width: 50%;
    margin: 5px 0;
  }
  .meta-brand-more.meta-brand {
    margin-left: auto;
    margin-right: auto;
  }

  /* .feature h3 {
  font-size: 26px;
} */
  .feature p {
    font-size: 18px;
  }
  .metaportal-text {
    padding: 0;
  }
  .metaportal-text h4 {
    font-size: 18px;
  }
  .navbar .navbar-brand img {
    height: 16px;
  }
  .portal-text {
    margin-top: 22px;
  }
  .metaportal {
    padding-top: 50px;
  }
  .portal-name img {
    width: 100%;
  }

  .metaverse-brands h3 {
    font-size: 18px;
    width: 100%;
    line-height: 25px;
    margin-bottom: 50px;
  }
  .metaportal h1 {
    font-size: 26px;
  }
  .metaportal h1 {
    font-size: 26px;
    line-height: 31px;
  }

  .portal-text-header {
    font-size: 18px;
    letter-spacing: 0px;
  }

  .portal-text {
    font-size: 18px;
  }

  .portal-discord {
    position: static;
    margin-top: 16px;
  }
  .portal-discord a {
    width: 100%;
  }
  .portal-image img {
    width: 100%;
  }
  .portal-image {
    background-position: bottom;
    height: 100%;
    background-size: cover;
  }
  .contact-link-holder {
    margin-bottom: 0px;
    padding: 5px;
  }
  .contact-us-wrap {
    padding: 40px 0;
  }
  .subscribe-wrap {
    margin-top: 50px;
  }
  .subscribe-wrap h6 {
    margin-bottom: 10px;
    font-size: 13px;
  }
  #button-sub,
  .button-sub {
    position: static;
    width: 100%;
    border: 1px solid;
    margin-top: 14px;
    padding: 0;
  }
  .social-icons,
  .footer-links,
  .copyright-text {
    position: static;
    margin-top: 10px;
    margin-bottom: 30px;
    float: none;
  }
  .metaverse-section:before {
    height: 100px;
  }
  #features-model {
    height: 320px;
  }

  .careers-wrapper h1 {
    font-size: 30px;
  }
  .careers-wrapper .careers-description {
    width: 100%;
    font-size: 14px;
    line-height: 20px;
    margin-top: 10px;
  }

  #recruitee-careers .rt-list__offer-title {
    font-size: 26px !important;
  }
  #recruitee-careers .rt-list__offer-item span:not(.rt-list__offer-title) {
    font-size: 14px !important;
  }
  .video-positioner {
    top: 50%;
    transform: translateY(-50%);
  }
  .btn.darkbtn {
    height: 50px;
  }
  .navbar.bg-transparent .navbar-nav a.nav-link {
    font-family: "TTFirsNeue-Medium";
    line-height: 60px;
    letter-spacing: 0px;
    font-size: 30px;
  }
  .home-link-mobile {
    display: block;
  }
  .meta-investor {
    margin: 20px 10px;
    min-width: 25%;
  }
  .ventureBeatText {
    margin-top: 50px;
    margin-bottom: 100px;
  }
  .ventureBeatText h3 {
    font-size: 18px;
    line-height: 20px;
  }

  /* Metaportal drop css */
  .drophero-text .drop-section-header {
    font-size: 20px;
    line-height: 28px;
  }
  .drophero-text {
    position: absolute;
    text-align: left;
    padding: 0;
    width: 100%;
    line-height: 1px;
  }
  .drop-hero-detail,
  .video-desc {
    font-size: 24px;
    line-height: 32px;
    margin: 32px 0;
    text-align: center;
  }
  .drop-hero-powered {
    font-size: 14px;
    margin-bottom: 32px;
    line-height: 20px;
    padding: 0 32px;
  }

  .metamundo-details {
    font-size: 18px;
    line-height: 27px;
    padding: 80px 32px;
    width: 100%;
  }

  .container.drop-container {
    display: block;
    padding: 20px 32px;
    padding-bottom: 60px;
    width: auto;
  }

  .metamundo-partners .container.drop-container {
    padding: 80px 32px;
  }

  #dropHero2 {
    width: 100%;
    margin-bottom: 64px;
  }

  .drop-hero-about {
    text-align: left;
    position: relative;
    top: auto;
    transform: none;
    padding: 0;
  }
  .drop-container-left {
    width: auto;
  }
  #metaportal video {
    width: 100%;
  }

  .metamundo-benefits .drop-container-right {
    display: none;
  }
  .metamundo-roadmap .drop-section-header {
    margin-top: 64px;
  }

  .metamundo-roadmap .drop-container-right {
    padding: 0px;
  }
  .metamundo-distribution .drop-container {
    display: flex;
    flex-direction: column-reverse;
  }
  .metamundo-distribution .drop-container-right {
    padding: 0px;
  }

  .slick-list {
    width: 100%;
  }
  .slick-list video {
    height: auto;
    width: 100%;
  }

  .drop-hero2-description {
    margin-top: 114px;
  }

  .slick-dots {
    bottom: 24px;
  }

  .new-feature {
    font-size: 12px;
  }

  .ogdrop-wrapper .slick-dots li button:hover:before {
    opacity: 0.25;
  }
  .ogdrop-wrapper .slick-dots li.slick-active button:before {
    opacity: 0.75;
  }

  .metaportal .drop-hero2-description {
    margin-top: 40px;
  }

  .countdown-value {
    font-size: 48px;
  }
  .coundown-interval {
    margin-right: 10px;
    margin-left: 10px;
  }

  .portal-name img {
    height: 46px;
  }
  .metamundo-partners h3 {
    font-size: 18px;
  }
  .metaportal-partners {
    text-align: center;
  }
  .metaportal-partner-link img {
    width: 90%;
    height: 90%;
  }
  a.metaportal-partner-link {
    margin-right: 12px;
    width: 28%;
  }
  .metaportal-partner-testimonial {
    width: 100%;
    font-size: 18px;
    line-height: 27px;
  }

  .team-intro h1 {
    font-size: 24px;
    line-height: 32px;
  }

  .team-intro {
    padding-top: 198px;
  }

  .team-intro-sub {
    font-size: 14px;
    line-height: 20px;
    width: 100%;
    padding: 0 35px;
    margin-top: 8px;
  }

  .team-we-are-hiring {
    margin-top: 10px;
    font-size: 14px;
    line-height: 20px;
  }

  .team-section {
    padding-top: 48px;
  }
  .team-section h2 {
    font-size: 20px;
    line-height: 28px;
  }

  .team-employees {
    width: 100%;
    margin-top: 25px;
  }
  .team-employee {
    padding: 0 38px;
  }
  .employee-image {
    width: 100%;
  }

  .team-employee-wrap {
    margin-bottom: 35px;
  }

  .angel-investors-wrap {
    padding: 0;
    line-height: 20px;
    margin-top: 10px;
  }
  .investor-section-header {
    font-size: 18px;
  }

  .angel-investor-section-header {
    font-size: 14px;
    text-align: left;
    line-height: 20px;
  }

  .metaportal-partners {
    margin-top: 16px;
  }

  .angel-investors-wrap a {
    font-size: 14px;
  }
  .terms-condition-link.visible-tc {
    visibility: visible;
    display: block;
  }

  /* Redemption Flow */
  .drophero-wrapper {
    padding-top: 100px;
  }
  .drop-hero-container {
    margin: 0 24px;
  }
  .drophero-mintpass {
    position: static;
    max-width: 100%;
    font-size: 12px;
    line-height: 20px;
    transform: none;
  }

  .drophero-text {
    position: static;
    transform: none;
    margin: 0;
    padding: 8px;
  }
  .drop-hero-countdown-container {
    text-align: center;
  }
  .ogdrop-discord a.btn {
    width: 100%;
    margin-bottom: 20px;
  }
  #connectWallet,
  .profile-url {
    display: none;
  }
  .mdot-connect-wallet {
    display: block;
    position: fixed;
    z-index: 10;
    right: 76px;
    top: 20px;
  }
  .modal-dialog.modal-dialog-centered {
    margin: 24px;
  }
  .modal-body {
    padding: 0 16px;
    padding-bottom: 16px;
  }
  .redemption-body {
    font-size: 12px;
    line-height: 16px;
  }
  .default-modal .redemption-header,
  .default-modal .redemption-body {
    padding-left: 54px;
  }
  .modal-title {
    font-size: 20px;
    line-height: 28px;
  }
  .modal-dialog input[type="text"] {
    width: 100%;
  }
  .loader-modal.modal-body {
    padding-bottom: 64px;
    text-align: center;
  }
  .profile-container.page-section {
    margin-left: 24px;
    margin-right: 24px;
  }
  .btn.sign-out-button.darkbtn {
    right: 50%;
    transform: translateX(50%);
  }
  .collected-nft {
    width: 100%;
  }
  .nft-name {
    font-size: 14px;
    line-height: 20px;
  }
  .nft-sold-by {
    font-size: 12px;
    line-height: 16px;
  }
  .download-files-subtitle {
    display: none;
  }
  .downloadfiles-modal .modal-body {
    padding-left: 16px;
    padding-right: 16px;
  }
  .downloadble-files {
    margin: 0;
    padding: 8px;
    font-size: 12px;
    line-height: 16px;
  }
  .portal {
    width: calc(100% - 4px);
    padding: 16px;
  }
  .portal h1 {
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 24px;
  }
  .mm-teleport {
    background-size: contain;
    background-position: top;
  }
}
