@import url(https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css);
@font-face {
  font-family: "TTFirsNeue-Regular";
  src: url(../../static/media/TTFirsNeue-Regular.752760b4.woff2) format("woff2");
}
@font-face {
  font-family: "TTFirsNeue-DemiBold";
  src: url(../../static/media/TTFirsNeue-DemiBold.8555cae0.woff2) format("woff2");
}

@font-face {
  font-family: "TTFirsNeue-Medium";
  src: url(../../static/media/TTFirsNeue-Medium.524b1e0b.woff2) format("woff2");
}
@font-face {
  font-family: "Uncode";
  src: url(../../static/media/uncode.c97e01c5.woff2) format("woff2");
}

html,
body {
  min-height: 100%;
  font-family: "TTFirsNeue-Regular";
}

.TTFirsNeue {
  font-family: "TTFirsNeue-Regular" !important;
}

.TTFirsNeue-Medium {
  font-family: "TTFirsNeue-Medium" !important;
}
.unCode {
  font-family: "Uncode" !important;
}
.ogdrop-wrapper {
  font-family: "TTFirsNeue-Regular";
  background: #000;
}

.demibold {
  font-family: "TTFirsNeue-DemiBold" !important;
}

.header,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "TTFirsNeue-DemiBold", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: 600;
}
body {
  margin: 0;
  font-family: "TTFirsNeue-Regular", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.btn.darkbtn.greenBtn {
  background: #00ff95;
  border-radius: 8px;
  color: #000;
  border: none;
  font-size: 16px;
  letter-spacing: 0px;
  line-height: 52px;
  padding: 0 20px;
}

a.link-orange,
.color-orange {
  color: #fa8564;
  text-decoration: none;
}
.btn {
  cursor: pointer;
}
.btn.lightbtn {
  border-radius: 8px;
  color: #fff;
  border: 2px solid #fff;
  font-size: 16px;
  letter-spacing: 0px;
  line-height: 52px;
  padding: 0 20px;
}

.default-modal {
  font-size: 14px;
}

.modal-header {
  border: none;
  padding-bottom: 24px;
  padding-top: 40px;
}
.modal-body {
  padding: 0 56px;
  padding-bottom: 40px;
}
.modal-title {
  text-align: center;
  width: 100%;
  line-height: 32px;
  padding: 0 50px;
}
.default-modal .redemption-header,
.default-modal .redemption-body {
  padding-left: 64px;
  margin-bottom: 5px;
}
.modal-dialog {
  max-width: 536px;
  line-height: 20px;
}

.modal-dialog button.btn-close {
  position: absolute;
  top: 34px;
  right: 34px;
}

header {
  position: absolute;
  width: 100%;
  z-index: 5;
  top: 0px;
  background: black;
  position: fixed;
  border-bottom: 1px solid #333333;
}

header.container {
  max-width: 100%;
}
header.header {
  left: 50%;
  /*padding:5px;*/
  padding-left: 100px;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  background-color: rgba(0, 0, 0, 0.85);
}
/* .navbar-nav {
    margin-right: auto!important;
    margin-left: auto!important;
    font-family: 'BandeinsStrangeSB';
} */

.navbar {
  padding-top: 0;
  padding-bottom: 0;
}

.navbar.bg-transparent .navbar-nav a.nav-link {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 14px;
  color: #fff;
  transition: 0.2s all;
  font-family: "TTFirsNeue-Medium";
  text-transform: uppercase;
  font-weight: normal;
  border-left: 1px solid #333333;
  letter-spacing: 0px;
}

.navbar .navbar-brand {
  position: absolute;
  left: 0px;
  color: #fff;
  font-size: 24px;
  letter-spacing: 1px;
  padding-top: 20px;
  padding-bottom: 20px;
}
.navbar .navbar-brand img {
  height: 20px;
}
.navbar-light .navbar-toggler {
  border: none;
}
.main-logo {
  margin-right: 24px;
}
.main-logo path {
  opacity: 0;
}
.navbar.navbar-light .navbar-toggler-icon {
  background: transparent;
  height: 2px;
  position: relative;
  transition: 0.25s ease-in-out;
}
.dark-header .navbar.navbar-light .collapsed .navbar-toggler-icon,
.dark-header .navbar.navbar-light .collapsed span.navbar-toggler-icon:before,
.dark-header .navbar.navbar-light .collapsed span.navbar-toggler-icon:after {
  background: #000;
}

.navbar.navbar-light .collapsed .navbar-toggler-icon {
  background: #fff;
  left: 0px;
}

.navbar.navbar-light span.navbar-toggler-icon:before {
  background: #000;
  content: "";
  position: absolute;
  top: 0px;
  height: 2px;
  width: 100%;
  left: 0px;
  transition: 0.25s ease-in-out;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

.navbar.navbar-light .collapsed span.navbar-toggler-icon:before {
  background: #fff;
}
.navbar.navbar-light .collapsed span.navbar-toggler-icon:after {
  background: #fff;
}
.navbar.navbar-light .collapsed span.navbar-toggler-icon:before {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  top: -10px;
}
.navbar.navbar-light span.navbar-toggler-icon:after {
  background: #000;
  content: "";
  position: absolute;
  -webkit-transform: rotate(138deg);
          transform: rotate(138deg);
  top: 0px;
  height: 2px;
  width: 100%;
  left: 0px;
  transition: 0.25s ease-in-out;
}
.navbar.navbar-light .collapsed span.navbar-toggler-icon:after {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  top: 10px;
}

.navbar-toggler:focus {
  box-shadow: none;
}

.btn.darkbtn span {
  margin-right: 15px;
}

.discord-link {
  position: absolute;
  right: 0px;
}
.black-logo {
  display: none;
}
.home-link-mobile {
  display: none;
}
.join-discord-mobile {
  margin-right: 0px;
  position: absolute;
  right: 80px;
  display: none;
}

.new-feature {
  font-size: 12px;
  line-height: 15px;
  color: #00ff95;
  border: 1px solid #00ff95;
  padding: 4px;
  margin-right: 7px;
  border-radius: 4px;
}

#connectWallet {
  font-family: "TTFirsNeue-Medium";
  margin: 10px;
  display: none;
}
.user-icon {
  margin: 10px 20px;
  border-radius: 4px;
  color: #fff;
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.user-profile {
  background-image: linear-gradient(135deg, #00ff95, #fff);
  height: 30px;
  width: 30px;
  display: inline-block;
  border-radius: 50%;
  position: relative;
  top: 10px;
  margin-right: 20px;
}
.profile-url {
  text-decoration: none;
}
@media only screen and (min-width: 769px) {
  /* .navbar.bg-transparent .navbar-nav a.nav-link:hover {
        color: #fff;
        transform: scale(1.1);
    } */
}

.mm-teleport {
  height: 100vh;
  width: 100vw;
  background-image: url(../../static/media/bg.92a66ef2.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #000;
}

.btn.darkbtn {
  background: #fa8564;
  border-radius: 8px;
  color: #000;
  border: none;
  font-size: 16px;
  letter-spacing: 0px;
  line-height: 52px;
  padding: 0 20px;
  border: none !important;
}
.portal {
  width: 472px;
  box-sizing: border-box;
  padding: 40px 40px 40px 56px;
  border: 1px solid #3c3c3c;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
          transform: translateX(-50%) translateY(-50%);
  font-size: 14px;
  line-height: 20px;
  background: rgba(0, 0, 0, 0.75);
  color: #ffffff;
}
.portal h1 {
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 24px;
}
#connect-wallet {
  margin-top: 24px;
  font-size: 14px;
  width: 100%;
}

.modal-dialog .btn.darkbtn {
  font-family: "TTFirsNeue-Medium";
  font-size: 16px;
}
.modal-dialog .btn.darkbtn {
  width: 100%;
}
.modal-dialog .btn.darkbtn:hover {
  border: 1px solid #000;
}
.no-nft-modal .modal-bottom {
  margin-top: 32px;
}
.modalError {
  margin-top: 24px;
  background: rgba(255, 94, 67, 0.25);
  border-radius: 4px;
  padding: 16px;
  text-align: center;
  color: #db4830;
  font-size: 12px;
}
.modalSuccess {
  margin-top: 24px;
  background: #00cc77;
  border-radius: 4px;
  padding: 16px;
  text-align: center;
  color: #fff;
  font-size: 12px;
}
.no-nft-modal {
  text-align: center;
  line-height: 24px;
}
.redemption-spinner {
  text-align: center;
  margin: 40px 0;
}

.spinner-border {
  width: 60px;
  height: 60px;
}
.notMainNet {
  position: relative;
  top: 62px;
  background: #ffd7d0;
  color: #db4830;
  font-size: 14px;
  line-height: 20px;
  padding: 10px;
  z-index: 1;
  cursor: pointer;
  text-align: center;
}


@media only screen and (max-width: 992px) {
  .body {
    line-height: 24px;
  }
  .container {
    padding-left: 20px;
    padding-right: 20px;
  }
  .main-text {
    font-size: 41px;
  }

  .new-feature {
    position: relative;
    top: 18px;
    float: right;
  }

  .main-subtext {
    font-size: 14px;
  }
  header.header {
    padding: 0px;
  }
  .navbar .navbar-brand {
    position: static;
    font-size: 0;
    margin-left: 10px;
  }
  .mobile-nav-logo {
    display: block;
    position: absolute;
    top: 12px;
    left: 12px;
  }
  .navbar.bg-transparent .navbar-nav a.nav-link {
    color: white;
  }
  .navbar-light .navbar-toggler {
    position: relative;
    z-index: 10;
  }
  .navbar.navbar-light span.navbar-toggler-icon:before,
  .navbar.navbar-light span.navbar-toggler-icon:after {
    background: white;
  }

  .navbar.bg-transparent .navbar-nav a.nav-link {
    text-transform: none;
  }

  .navbar-nav {
    text-align: left;
    margin-top: 50px;
    display: none;
  }

  .navbar-collapse.show {
    background: black;
    height: 100vh;
    position: fixed;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
  }
  .show .navbar-nav {
    display: block;
  }
  .portal-text-left {
    width: 100%;
  }
  .discord-link {
    position: absolute;
    bottom: 10px;
    width: 100%;
    padding: 0 20px;
  }
  .discord-link .join-discord-nav {
    width: 100%;
    line-height: 50px;
    border-radius: 3px;
    display: block;
  }

  .feature {
    margin-bottom: 50px;
  }
  .navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 1%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  }

  #intro-video {
    width: 72%;
  }
  .video-desc {
    font-size: 20px;
    line-height: 30px;
    margin-top: 30px;
    width: 100%;
    padding: 0 20px;
  }
  .page-section {
    margin: 100px auto;
  }

  .section-header {
    font-size: 18px;
    font-weight: 700;
  }

  .section-subheader {
    font-size: 14px;
  }

  .brand {
    margin-right: 0;
    width: 50%;
    display: inline-block;
    margin-bottom: 30px;
    padding: 0 35px;
  }

  .brand img {
    width: 100%;
    max-height: 50px;
  }

  .row.features-row {
    margin-top: 50px;
  }

  .feature-image {
    height: 120px;
  }
  .features-wrap {
    padding: 10px;
    padding-bottom: 0px;
  }
  h4.feature-title {
    font-size: 16px;
    font-weight: 700;
  }

  h4.feature-subtitle {
    font-size: 12px;
  }

  .product-feature:not(:last-child) {
    margin-bottom: 40px;
  }

  .product-train {
    height: 300px;
  }

  .early-access-container {
    padding: 10px;
  }

  .early-access-wrap {
    padding: 20px;
  }

  .early-access h3 {
    font-size: 18px;
  }

  .early-access h4 {
    font-size: 12px;
  }

  .early-access input {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
  }

  .faq-wrap .accordion-button {
    font-size: 14px;
  }

  .contact-link-holder a {
    font-size: 30px;
    font-weight: 600;
  }

  .contact-link-holder {
    padding: 10px;
  }

  @-webkit-keyframes animatedBackgroundright {
    from {
      background-position: 0 0;
    }
    to {
      background-position: 100% 0;
    }
  }

  @keyframes animatedBackgroundright {
    from {
      background-position: 0 0;
    }
    to {
      background-position: 100% 0;
    }
  }
  @-webkit-keyframes animatedBackgroundleft {
    from {
      background-position: 100% 0;
    }
    to {
      background-position: 0 0;
    }
  }
  @keyframes animatedBackgroundleft {
    from {
      background-position: 100% 0;
    }
    to {
      background-position: 0 0;
    }
  }
  .product-train-1 {
    -webkit-animation: animatedBackgroundleft 20s linear infinite;
            animation: animatedBackgroundleft 20s linear infinite;
  }
  .product-train-2 {
    -webkit-animation: animatedBackgroundright 20s linear infinite;
            animation: animatedBackgroundright 20s linear infinite;
  }
  .models-list,
  .contact-us-wrap {
    background-size: cover;
  }
  /*
  .metaverse-section {
    padding-top: 196px;
  }*/

  .og-title {
    text-align: center;
  }
  .metaverse-desc {
    font-size: 18px;
    line-height: 24px;
    width: 100%;
  }
  .metaverse-section.page-section {
    margin-bottom: 50px;
  }

  .metaverse-brands {
    margin-top: 40px;
  }
  .meta-brand img {
    height: 24px;
    width: auto;
  }
  .features-container {
    padding: 0px;
  }
  .metaverse-brands .meta-brand {
    margin-bottom: 20px;
    width: 50%;
    margin: 5px 0;
  }
  .meta-brand-more.meta-brand {
    margin-left: auto;
    margin-right: auto;
  }

  /* .feature h3 {
  font-size: 26px;
} */
  .feature p {
    font-size: 18px;
  }
  .metaportal-text {
    padding: 0;
  }
  .metaportal-text h4 {
    font-size: 18px;
  }
  .navbar .navbar-brand img {
    height: 16px;
  }
  .portal-text {
    margin-top: 22px;
  }
  .metaportal {
    padding-top: 50px;
  }
  .portal-name img {
    width: 100%;
  }

  .metaverse-brands h3 {
    font-size: 18px;
    width: 100%;
    line-height: 25px;
    margin-bottom: 50px;
  }
  .metaportal h1 {
    font-size: 26px;
  }
  .metaportal h1 {
    font-size: 26px;
    line-height: 31px;
  }

  .portal-text-header {
    font-size: 18px;
    letter-spacing: 0px;
  }

  .portal-text {
    font-size: 18px;
  }

  .portal-discord {
    position: static;
    margin-top: 16px;
  }
  .portal-discord a {
    width: 100%;
  }
  .portal-image img {
    width: 100%;
  }
  .portal-image {
    background-position: bottom;
    height: 100%;
    background-size: cover;
  }
  .contact-link-holder {
    margin-bottom: 0px;
    padding: 5px;
  }
  .contact-us-wrap {
    padding: 40px 0;
  }
  .subscribe-wrap {
    margin-top: 50px;
  }
  .subscribe-wrap h6 {
    margin-bottom: 10px;
    font-size: 13px;
  }
  #button-sub,
  .button-sub {
    position: static;
    width: 100%;
    border: 1px solid;
    margin-top: 14px;
    padding: 0;
  }
  .social-icons,
  .footer-links,
  .copyright-text {
    position: static;
    margin-top: 10px;
    margin-bottom: 30px;
    float: none;
  }
  .metaverse-section:before {
    height: 100px;
  }
  #features-model {
    height: 320px;
  }

  .careers-wrapper h1 {
    font-size: 30px;
  }
  .careers-wrapper .careers-description {
    width: 100%;
    font-size: 14px;
    line-height: 20px;
    margin-top: 10px;
  }

  #recruitee-careers .rt-list__offer-title {
    font-size: 26px !important;
  }
  #recruitee-careers .rt-list__offer-item span:not(.rt-list__offer-title) {
    font-size: 14px !important;
  }
  .video-positioner {
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
  }
  .btn.darkbtn {
    height: 50px;
  }
  .navbar.bg-transparent .navbar-nav a.nav-link {
    font-family: "TTFirsNeue-Medium";
    line-height: 60px;
    letter-spacing: 0px;
    font-size: 30px;
  }
  .home-link-mobile {
    display: block;
  }
  .meta-investor {
    margin: 20px 10px;
    min-width: 25%;
  }
  .ventureBeatText {
    margin-top: 50px;
    margin-bottom: 100px;
  }
  .ventureBeatText h3 {
    font-size: 18px;
    line-height: 20px;
  }

  /* Metaportal drop css */
  .drophero-text .drop-section-header {
    font-size: 20px;
    line-height: 28px;
  }
  .drophero-text {
    position: absolute;
    text-align: left;
    padding: 0;
    width: 100%;
    line-height: 1px;
  }
  .drop-hero-detail,
  .video-desc {
    font-size: 24px;
    line-height: 32px;
    margin: 32px 0;
    text-align: center;
  }
  .drop-hero-powered {
    font-size: 14px;
    margin-bottom: 32px;
    line-height: 20px;
    padding: 0 32px;
  }

  .metamundo-details {
    font-size: 18px;
    line-height: 27px;
    padding: 80px 32px;
    width: 100%;
  }

  .container.drop-container {
    display: block;
    padding: 20px 32px;
    padding-bottom: 60px;
    width: auto;
  }

  .metamundo-partners .container.drop-container {
    padding: 80px 32px;
  }

  #dropHero2 {
    width: 100%;
    margin-bottom: 64px;
  }

  .drop-hero-about {
    text-align: left;
    position: relative;
    top: auto;
    -webkit-transform: none;
            transform: none;
    padding: 0;
  }
  .drop-container-left {
    width: auto;
  }
  #metaportal video {
    width: 100%;
  }

  .metamundo-benefits .drop-container-right {
    display: none;
  }
  .metamundo-roadmap .drop-section-header {
    margin-top: 64px;
  }

  .metamundo-roadmap .drop-container-right {
    padding: 0px;
  }
  .metamundo-distribution .drop-container {
    display: flex;
    flex-direction: column-reverse;
  }
  .metamundo-distribution .drop-container-right {
    padding: 0px;
  }

  .slick-list {
    width: 100%;
  }
  .slick-list video {
    height: auto;
    width: 100%;
  }

  .drop-hero2-description {
    margin-top: 114px;
  }

  .slick-dots {
    bottom: 24px;
  }

  .new-feature {
    font-size: 12px;
  }

  .ogdrop-wrapper .slick-dots li button:hover:before {
    opacity: 0.25;
  }
  .ogdrop-wrapper .slick-dots li.slick-active button:before {
    opacity: 0.75;
  }

  .metaportal .drop-hero2-description {
    margin-top: 40px;
  }

  .countdown-value {
    font-size: 48px;
  }
  .coundown-interval {
    margin-right: 10px;
    margin-left: 10px;
  }

  .portal-name img {
    height: 46px;
  }
  .metamundo-partners h3 {
    font-size: 18px;
  }
  .metaportal-partners {
    text-align: center;
  }
  .metaportal-partner-link img {
    width: 90%;
    height: 90%;
  }
  a.metaportal-partner-link {
    margin-right: 12px;
    width: 28%;
  }
  .metaportal-partner-testimonial {
    width: 100%;
    font-size: 18px;
    line-height: 27px;
  }

  .team-intro h1 {
    font-size: 24px;
    line-height: 32px;
  }

  .team-intro {
    padding-top: 198px;
  }

  .team-intro-sub {
    font-size: 14px;
    line-height: 20px;
    width: 100%;
    padding: 0 35px;
    margin-top: 8px;
  }

  .team-we-are-hiring {
    margin-top: 10px;
    font-size: 14px;
    line-height: 20px;
  }

  .team-section {
    padding-top: 48px;
  }
  .team-section h2 {
    font-size: 20px;
    line-height: 28px;
  }

  .team-employees {
    width: 100%;
    margin-top: 25px;
  }
  .team-employee {
    padding: 0 38px;
  }
  .employee-image {
    width: 100%;
  }

  .team-employee-wrap {
    margin-bottom: 35px;
  }

  .angel-investors-wrap {
    padding: 0;
    line-height: 20px;
    margin-top: 10px;
  }
  .investor-section-header {
    font-size: 18px;
  }

  .angel-investor-section-header {
    font-size: 14px;
    text-align: left;
    line-height: 20px;
  }

  .metaportal-partners {
    margin-top: 16px;
  }

  .angel-investors-wrap a {
    font-size: 14px;
  }
  .terms-condition-link.visible-tc {
    visibility: visible;
    display: block;
  }

  /* Redemption Flow */
  .drophero-wrapper {
    padding-top: 100px;
  }
  .drop-hero-container {
    margin: 0 24px;
  }
  .drophero-mintpass {
    position: static;
    max-width: 100%;
    font-size: 12px;
    line-height: 20px;
    -webkit-transform: none;
            transform: none;
  }

  .drophero-text {
    position: static;
    -webkit-transform: none;
            transform: none;
    margin: 0;
    padding: 8px;
  }
  .drop-hero-countdown-container {
    text-align: center;
  }
  .ogdrop-discord a.btn {
    width: 100%;
    margin-bottom: 20px;
  }
  #connectWallet,
  .profile-url {
    display: none;
  }
  .mdot-connect-wallet {
    display: block;
    position: fixed;
    z-index: 10;
    right: 76px;
    top: 20px;
  }
  .modal-dialog.modal-dialog-centered {
    margin: 24px;
  }
  .modal-body {
    padding: 0 16px;
    padding-bottom: 16px;
  }
  .redemption-body {
    font-size: 12px;
    line-height: 16px;
  }
  .default-modal .redemption-header,
  .default-modal .redemption-body {
    padding-left: 54px;
  }
  .modal-title {
    font-size: 20px;
    line-height: 28px;
  }
  .modal-dialog input[type="text"] {
    width: 100%;
  }
  .loader-modal.modal-body {
    padding-bottom: 64px;
    text-align: center;
  }
  .profile-container.page-section {
    margin-left: 24px;
    margin-right: 24px;
  }
  .btn.sign-out-button.darkbtn {
    right: 50%;
    -webkit-transform: translateX(50%);
            transform: translateX(50%);
  }
  .collected-nft {
    width: 100%;
  }
  .nft-name {
    font-size: 14px;
    line-height: 20px;
  }
  .nft-sold-by {
    font-size: 12px;
    line-height: 16px;
  }
  .download-files-subtitle {
    display: none;
  }
  .downloadfiles-modal .modal-body {
    padding-left: 16px;
    padding-right: 16px;
  }
  .downloadble-files {
    margin: 0;
    padding: 8px;
    font-size: 12px;
    line-height: 16px;
  }
  .portal {
    width: calc(100% - 4px);
    padding: 16px;
  }
  .portal h1 {
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 24px;
  }
  .mm-teleport {
    background-size: contain;
    background-position: top;
  }
}

